import React from "react";
const Analytics = () => {
  return (
    <>
      <div className=" bg-red ">
        <iframe
          className="tableStyle"
          width="1000"
          height="500"
          src="https://charts.mongodb.com/charts-project-0-ojmww/embed/charts?id=015f98cd-ddec-4f53-b0f5-04ee548c03e4&maxDataAge=36000&theme=light&autoRefresh=false"
        ></iframe>
      </div>
    </>
  );
};
export default Analytics;
